/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

* {
  font-family: var(--ionic-main-font);
}

// HTML tags
img {
  width: auto;
}

ion-content {
  --background: var(--ion-color-white);
}

ion-modal.popup-confirm {
  --height: 40%;
  --width: 80%;
  --max-width: 400px;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --backdrop-opacity: 0.5!important;
}
ion-modal.normal {
  --width: 90%;
  --height: 110%;
  --border-radius: 16px;
  padding-top: 8em;
  &-second {
    padding-top: 10em;
  }
}
ion-modal.half-screen {
  --backdrop-opacity: 0;
  --border-radius: 16px;
  --height: 50vh;
  --top: 50vh;
  height: var(--height);
  top: var(--top);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.modal-half-screen-content {
  height: 100%;
  overflow-y: auto;
}

.modal-half-screen-container {
  position: relative;
}

.modal-half-screen-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.modal-half-screen-backdrop.visible {
  opacity: 1;
  pointer-events: auto;
}

ion-card {
  border-radius: 8px;
}
ion-card.clickable {
  cursor: pointer;
}
ion-card.grey-light {
  --background: var(--ion-color-grey-light);
  border: 1px solid var(--ion-color-grey);
  box-shadow: unset;
}
ion-card.with-margin {
  margin-top: 3em;
  margin-bottom: 3em;
}

// classes
.g-no-margin {
  margin: 0!important;
  &--top {
    margin-top: 0!important;
  }
  &--left {
    margin-left: 0!important;
  }
}

.g-header {
  --background: var(--ion-color-primary);
  &--text {
    color: var(--ion-color-white);
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    &-center {
      text-align: center;
    }
  }
  &--block {
    display: flex;
    position: relative;
  }
  &--button-left {
    cursor: pointer;
    margin-left: 1em;
    margin-right: -1em;
  }
}

.g-heading {
  font-family: var(--ionic-main-font-bold);
  font-size: 42px;
  &--small {
    font-size: 36px;
  }
}

.g-title {
  font-family: var(--ionic-secondary-font-bold);
  font-size: 16px;
  &--small {
    font-size: 14px;
  }
  &--big {
    font-size: 24px;
  }
}

.g-text {
  font-size: 13px;
  &--secondary {
    color: var(--ion-color-text-secondary);
  }
  &--red {
    color: var(--ion-color-danger);
  }
  &--error {
    font-size: 14px;
    font-family: var(--ionic-secondary-font-bold);
    color: var(--ion-color-danger);
  }
  &--link {
    cursor: pointer;
    color: var(--ion-color-link);
  }
  &--white {
    color: var(--ion-color-white);
  }
  &--green {
    color: var(--ion-color-success);
  }
}

.g-input {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid var(--ion-color-grey);
  border-radius: 8px;
  height: 3.5em;
  width: 100%;
  font-size: 13px;
  padding-left: 10px;
  color: var(--ion-color-black);
  &--label {
    display: block;
    font-family: var(--ionic-secondary-font-bold);
    font-size: 16px;
    color: var(--ion-color-text-secondary);
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }
  &--padding-icon {
    padding-left: 35px;
  }
  &--skinny {
    height: unset;
  }
  &--big {
    height: 7em;
  }
  &--multiple {
    flex: 1;
  }
}

.g-checkbox {
  --border-color: var(--ion-color-link);
  --border-color-checked: var(--ion-color-text-primary);
  --border-radius: 4px;
  --checkbox-background-checked: #FFFFFF;
  --checkmark-color: var(--ion-color-link);
}

.g-button {
  --background: var(--ion-color-primary);
  --background-focused: var(--ion-color-terciary);
  --background-hover: var(--ion-color-grey-dark);
  --border-radius: 10px;
  text-transform: none;
  letter-spacing: normal;
  font-family: var(--ionic-secondary-font-bold);
  font-size: 16px;
  height: 3em;
  &--secondary {
    --border-radius: 10px;
    --border-width: 1px;
    text-transform: none;
    letter-spacing: normal;
    font-family: var(--ionic-secondary-font-bold);
    font-size: 16px;
    height: 3em;
    --color: var(--ion-color-primary);
    --border-color: var(--ion-color-primary);
    &-white {
      --background: var(--ion-color-white);
    }
    &-google {
      --color: var(--ion-color-link);
      --border-color: var(--ion-color-link);
    }
  }
  &--icon-left {
    margin-right: 10px;
  }
  &--multiple {
    flex: 1;
  }
}

.g-line {
  height: 1px;
  background-color: var(--ion-color-grey);
  margin: 5px 0 5px;
  &--white {
    background-color: var(--ion-color-white);
  }
  &--container {
    position: absolute;
    left: 0;
    right: 0;
  }
}
