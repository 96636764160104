// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #113055;
  --ion-color-secondary: #4ba4c6;
  --ion-color-terciary: #001141;

  --ion-color-success: #34a353;
  --ion-color-warning: #f6b05d;
  --ion-color-danger: #d13030;
  --ion-color-success-hover: #174925;
  --ion-color-warning-hover: #4a351b;
  --ion-color-danger-hover: #511313;

  --ion-color-text-primary: #11142d;
  --ion-color-text-secondary: #5a6a85;
  --ion-color-link: #4280ef;
  --ion-color-link-hover: #152d57;

  --ion-color-grey-dark: #868eab;
  --ion-color-grey: #e6e7f1;
  --ion-color-grey-light: #f2f3f9;
  --ion-color-white: #f6f9fc;
  --ion-color-black: #2c2c2c;

  --ionic-main-font: 'Inter';
  --ionic-main-font-bold: 'Inter Bold';
  --ionic-secondary-font: 'Plus Jakarta Sans';
  --ionic-secondary-font-bold: 'Plus Jakarta Sans Semibold';
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter.woff') format('woff');
}

@font-face {
  font-family: 'Inter Bold';
  src: url('../assets/fonts/inter_bold.woff') format('woff');
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../assets/fonts/plus_jakarta_sans.woff') format('woff');
}

@font-face {
  font-family: 'Plus Jakarta Sans Semibold';
  src: url('../assets/fonts/plus_jakarta_sans_semibold.woff') format('woff');
}
